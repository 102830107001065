import axios from "axios";
import JwtService from "./jwt.service";
import router from "@/router";

export const apiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${JwtService.getToken()}`,
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
  },
});

apiService.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiService.interceptors.response.use(
  function (response) {
    if (response.data.code === 401) {
      JwtService.destroyToken();
      localStorage.removeItem("user");
      router.push({ name: "login" }).catch(() => {});
    }
    if (response.data.code === 403) {
      router.push({ name: "dashboard" }).catch(() => {});
    }
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      JwtService.destroyToken();
      router.push({ name: "login" });
    }

    return Promise.reject(error.response);
  }
);
