import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import {baseMixin} from "./mixin";
// import VueEditor from 'vue2-editor';

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import JwtService from "@/services/jwt.service";

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Composition API
Vue.use(VueCompositionAPI)
// Vue.use(VueEditor);

Vue.mixin(baseMixin)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const pageTitle = to.matched.find((item) => item.meta.pageTitle);
  if (pageTitle) window.document.title = pageTitle.meta.pageTitle;
  store.dispatch('app/setAdminInfo')
  if (to.meta.notAuthenticated) {
    next()
  } else {
    if(localStorage.getItem('id_admin_token')) {
      next();
    } else {
      next({ name: 'login' })
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
