export const STATUS = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 422,
  ERROR: 500,
  NOT_FOUND: 404,
  FAIL: 400,
  AUTHORIZATION: 403,
  UNAUTHORIZED: 401,
  MESSENGER_CREATE_SUCCESS: "メールで送信が完了しました。",
  MESSENGER_UPDATE_SUCCESS: "更新が完了しました。",
  MESSENGER_DELETE_SUCCESS: "削除が完了しました。",
  MESSENGER_END_ROOM_SUCCESS : '強制終了が完了しました。',
  MESSENGER_ADMIN_SENT_SUCCESS : '送信しました。',
  NOTIFICATION_SAVE_DRAFT_SUCCESS : 'お知らせを下書き保存しました。',
};

export const SYSTEM_NOTIFY_STATUS = {
  1: "Sent",
  2: "Draft",
};

export const ROOM_STATUS = {
  1: "トーク中", //Activating
  2: "通報あり", //Reported
  3: "終了", //End
};

export const ROOM_TYPE = {
  1: "グループ", //Group
  2: "個人", //Personal
};

export const SEX_TYPE = {
  1: "男性", //Male
  2: "女性", //Female
};

export const SYSTEM_ADMIN = 'system_admin'
export const STAFF_ADMIN = 'staff_admin'
export const STATUS_IMG_ADS = {
    public: 1,
    private: 2,
}

export const RELOAD_TIME = 30 * 1000; //miliseconds

export const dashboardChartOption = {
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: false,
    curve: "straight",
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "right",
    fontSize: "13px",
    fontFamily: "Montserrat",
  },
  xaxis: {
    categories: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
  },
  yaxis: {
    title: {
      text: "(人)",
      rotate: 0,
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [95, 100],
    },
  },
  tooltip: {
    shared: false,
  },
  colors: ["#377dff", "#1dc5d0"],
};
export const limitPageInquiry = 15;
export const STATUS_NOFITY = {
  active : 1,
  draft : 2,
  sending : 3,
  sendFail : 4,
}
export const STATUS_DELETE_USER = 5;
