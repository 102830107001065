<template>
  <b-modal
    id="modal-prevent-closing"
    :size="size"
    v-model.sync="dialogControl"
    ref="my-modal"
    :title="title"
    :modal-class="classList"
    :ok-only="showOkButton && !showCancelButton"
    :cancel-only="!showOkButton && showCancelButton"
    :ok-title="btnOk"
    :cancel-title="btnCancel"
    :hide-footer="!showCancelButton && !showOkButton"
    :hide-header="hideHeader"
    :centered="centered"
    :ok-disabled="okBtnDisabled"
    :customTitle="customTitle"
    cancel-variant="danger"
    @hidden="handleClose"
    @ok="handleOk"
    @close="$emit('close')"
  >
    <template #modal-title v-if="customTitle === true">
      <slot name="title">        
      </slot>
    </template>
    <slot name="header" style="text-align: center"></slot>
    <slot name="content"></slot>
    <slot name="footer"></slot>
  </b-modal>
</template>

<script>
import {BModal, BButton} from 'bootstrap-vue'

export default {
  name: 'Dialog',
  components: {
    BModal,
    BButton
  },
  props: {
    checkForm: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    btnOk: {
      type: String,
      default: 'はい'
    },
    typeDialog: {
      type: String,
      default: ''
    },
    btnCancel: {
      type: String,
      default: 'キャンセル'
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showOkButton: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    width: {
      type: String,
      default: '30%'
    },
    classList: {
      type: String,
      default: 'modal-primary'
    },
    okBtnDisabled: {
      type : Boolean,
      default : false
    },
    btnCustom: {
      type: String,
      default: 'Custom'
    },
    customTitle: {
      type: Boolean,
      default: false
    },
    isDisplayBtnCustom: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
       handleOk(bvModalEvent) {    
        if (this.checkForm) {
         bvModalEvent.preventDefault()
        }
        this.$emit('submit');
      },
      handleClose(emit) {
        if(this.typeDialog) {
        this.$store.commit("app/TURN_OFF_NOTIFICATION");
        }
        this.$emit('closeDialog')
      }
  },
  computed: {
    dialogControl: {
      get () { return this.dialog },
      set (value) {
        this.$emit('update:dialog', value) },
    },
  },
};
</script>