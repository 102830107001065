import {db} from "@/firestore";
import { apiService } from '@/services/api.service'
import { STATUS } from '@/constant/constant'
import store from '@/store'

export default {
    namespaced: true,
    state : {
        totalNumberUnreadOfMessages : [],
        ADMIN_ID : 1,
        // create a variable that stores the id of the user that you click on. so that when the user clicks on the idea again, it won't reload
        statusDeleteTotalNumberOfMessage : false,
        matchUserVsInquiry : {},
        listIdUser : {
            idUnread: [],
            idNoRep:  []
        },
        listUserByIdSendMsgUnread : [],
        listTopicAdminSeenNotRep : [],
        listIdUserAdminSeenNotRep : [],
        topic_listener: null
    },
    getters : {
        getTotalNumberOfMsg : state =>{
            return state.totalNumberUnreadOfMessages.length;
        },
    },
    mutations : {
        SET_LIST_COUNT_UNREAD(state,payload){
            state.totalNumberUnreadOfMessages = payload;
        },
        PUSH_LIST_COUNT_UNREAD(state,inquiryUnread){
            let findItemUnread = state.totalNumberUnreadOfMessages.find(c =>c.chat_id === inquiryUnread.chat_id);
            if (!findItemUnread){
                state.totalNumberUnreadOfMessages.push(inquiryUnread);
            }
        },
        SPLICE_LIST_COUNT_UNREAD(state,removeIndex){
            state.totalNumberUnreadOfMessages.splice(removeIndex,1);
        },
        SPLICE_LIST_COUNT_UNREAD_DASHBOARD(state,removeIndex){
            state.listUserByIdSendMsgUnread.splice(removeIndex,1);
        },
        SET_STATUS_DELETE(state,status){
            state.statusDeleteTotalNumberOfMessage = status;
        },
        SET_MATCH_USER_VS_INQUIRY(state,user_id){
            state.matchUserVsInquiry.user_id = user_id;
        },
        PUSH_LIST_TOPIC_ADMIN_SEEN_NOT_REPLY(state,inquiry){
            let findTopicAdminSeenNotRepSteal = state.listTopicAdminSeenNotRep.find(c => c.chat_id === inquiry.chat_id);
            if (!findTopicAdminSeenNotRepSteal){
                state.listTopicAdminSeenNotRep.push(inquiry);
            }
        },
        PUSH_LIST_ID_USER_UNREAD(state,id){
            state.listIdUser.idUnread.push(id);
        },
        PUSH_LIST_ID_NOT_REP(state,id){
            state.listIdUser.idNoRep.push(id);
        },
        SET_LIST_USER_BY_ID_UNREAD_MSG(state,listUser){
            state.listUserByIdSendMsgUnread = listUser;
        },
        SET_LIST_INQUIRY_ADMIN_SEEN_NOT_REP(state,inquiry){
            state.listTopicAdminSeenNotRep = inquiry;
        },
        SPLICE_INQUIRY_ADMIN_SEEN_NOT_REP(state,index){
            state.listTopicAdminSeenNotRep.splice(index,1)
        },
        REPLACE_INQUIRY_ADMIN_SEEN_NOT_REP(state,context){
            state.listTopicAdminSeenNotRep.splice(context.index,1,context.inquiryReplace)
        },
        RESET_LIST_ID_USER(state){
            state.listIdUser = {
                idUnread: [],
                idNoRep:  []
            }
        },
        UPDATE_LIST_COUNT_UNREAD(state,context){
            state.listUserByIdSendMsgUnread[context.index].last_message = context.last_message;
            state.listUserByIdSendMsgUnread[context.index].last_message_at = context.last_message_at;
        }
    },
    actions : {
        totalCountUnread(context,listCountUnread){
            context.commit('SET_LIST_COUNT_UNREAD',listCountUnread);
        },
        matchUserVsInquiryChat(context,user_id){
            context.commit('SET_MATCH_USER_VS_INQUIRY',user_id);
        },
        async getListUserById(context,listId){
            let convertListIdToStringUnread = '';
            let convertListIdToStringNoRep = ''
            if (listId.idUnread.length && listId.idUnread.length > 1){
                convertListIdToStringUnread = listId.idUnread.join(',');
            }else{
                convertListIdToStringUnread = listId.idUnread[0] + '';
            }
            if (listId.idNoRep.length && listId.idNoRep.length > 1){
                convertListIdToStringNoRep = listId.idNoRep.join(',');
            }else {
                convertListIdToStringNoRep = listId.idNoRep[0] + '';
            }
            if (listId.idUnread.length || listId.idNoRep.length){
                try {
                    const res = await Promise.all([
                        await apiService.get(`manage-user/getListId/${convertListIdToStringUnread}`),
                        await apiService.get(`manage-user/getListId/${convertListIdToStringNoRep}`)
                    ]);
                    let data = res.map(res => {
                        return res.data.data;
                    })
                    // call data get list firebase
                        await context.state.totalNumberUnreadOfMessages.forEach(user => {
                            data[0].find(item => {
                                if (user.user_id[0] === item.id) {
                                    user.avatar = item.avatar;
                                    user.username = item.username;
                                    user.status = item.user_status;
                                }
                            })
                        })
                        await context.state.listTopicAdminSeenNotRep.forEach(topic =>{
                            data[1].find(item => {
                                if (topic.user_id[0] === item.id) {
                                    topic.avatar = item.avatar;
                                    topic.username = item.username;
                                    topic.status = item.user_status;
                                }
                            })
                        })
                        await context.commit('SET_LIST_USER_BY_ID_UNREAD_MSG',context.state.totalNumberUnreadOfMessages);
                        await context.commit('SET_LIST_INQUIRY_ADMIN_SEEN_NOT_REP',context.state.listTopicAdminSeenNotRep);
                }catch (e){
                    console.log(e)
                }
            }
        },
        unsubscribe(context) {
            context.state.topic_listener = db.collection('topics').onSnapshot(() => {})
        },
        checkStatusUpdateFirebase(context,dataUpdated){
            // check dataUpdated tồn tại trong các mảng này chưa.
            let findIndexToListTopicAdminSeenNotRep = context.state.listTopicAdminSeenNotRep.findIndex(item => item.chat_id === dataUpdated.chat_id);
            let findIndexListMessageUnread = context.state.listUserByIdSendMsgUnread.findIndex(item => item.chat_id === dataUpdated.chat_id);
            let findIndexTotalNumberUnread = context.state.totalNumberUnreadOfMessages.findIndex(item => item.chat_id === dataUpdated.chat_id);
            // * check data is updated (unread message, read, changed when sending new message).
            // case 1 : dataUpdated has count_unread_message ( admin unread messages ) and not in all 3 arrays. will add to the array of unread messages.
            if (dataUpdated.count_unread_message && findIndexListMessageUnread === -1 && findIndexTotalNumberUnread === -1 && findIndexToListTopicAdminSeenNotRep === -1 && !dataUpdated.admin_reply){
                context.commit('RESET_LIST_ID_USER');
                context.commit('PUSH_LIST_COUNT_UNREAD',dataUpdated);
                context.commit('PUSH_LIST_ID_USER_UNREAD',dataUpdated.user_id[0]);
                context.dispatch('getListUserById',context.state.listIdUser);
            }
            // case 2: (there is a new message, this message is already in the list of topicAdminSeenNotRep -> put that message in the list of unread messages.
            // delete from topic adminSeenNotRep )
            if (dataUpdated.count_unread_message && findIndexListMessageUnread === -1 && findIndexTotalNumberUnread === -1 && findIndexToListTopicAdminSeenNotRep !== -1 ){
                context.commit('RESET_LIST_ID_USER');
                context.commit('SPLICE_INQUIRY_ADMIN_SEEN_NOT_REP',findIndexToListTopicAdminSeenNotRep);
                context.commit('PUSH_LIST_COUNT_UNREAD',dataUpdated);
                context.commit('PUSH_LIST_ID_USER_UNREAD',dataUpdated.user_id[0]);
                context.dispatch('getListUserById',context.state.listIdUser);
            }
            // case 3: there is a new update, the message is already in the unread message list. but there is another machine click to read the message ->
            // remove dataUpdate from the list of unread messages -> scroll down to messages that have been viewed but have not been answered
            if (!dataUpdated.count_unread_message && findIndexListMessageUnread !== -1 && findIndexTotalNumberUnread !== -1 && !dataUpdated.admin_reply){
                context.commit('RESET_LIST_ID_USER');
                context.commit('SPLICE_LIST_COUNT_UNREAD',findIndexTotalNumberUnread);
                context.commit('SPLICE_LIST_COUNT_UNREAD_DASHBOARD',findIndexListMessageUnread);
                context.commit('PUSH_LIST_TOPIC_ADMIN_SEEN_NOT_REPLY',dataUpdated);
                context.commit('PUSH_LIST_ID_NOT_REP',dataUpdated.user_id[0]);
                context.dispatch('getListUserById',context.state.listIdUser);
            }
            // case 4: there is a new update. message is in listTopicAdminSeenNotRep . but there is another phone answering
            // remove message from listTopicAdminSeenNotRep
            if (findIndexToListTopicAdminSeenNotRep !== -1 && findIndexListMessageUnread === -1 && findIndexTotalNumberUnread ===-1 && dataUpdated.admin_reply && !dataUpdated.count_unread_message){
                context.commit('SPLICE_INQUIRY_ADMIN_SEEN_NOT_REP',findIndexToListTopicAdminSeenNotRep);
            }
            // case 5: there is a new update. The message is already in the unread message list. admin_reply = 0 ( admin has not read the message ) and count_unread exists
            // -> update unread message list data
            if (dataUpdated.count_unread_message && !dataUpdated.admin_reply && findIndexListMessageUnread !== -1){
                let indexReplace = context.state.listUserByIdSendMsgUnread.findIndex(c => c.chat_id === dataUpdated.chat_id);
                let updateMsgUnread = {
                    index: indexReplace ?? null,
                    last_message: dataUpdated.last_message,
                    last_message_at: dataUpdated.last_message_at
                }
                context.commit('UPDATE_LIST_COUNT_UNREAD', updateMsgUnread)
                // case 6 : admin doesn't click on unread topics, but keeps texting -> deletes the list from unread messages and not-rep messages
            }else if (!dataUpdated.count_unread_message && dataUpdated.admin_reply && findIndexListMessageUnread !== -1){
                context.commit('SPLICE_LIST_COUNT_UNREAD',findIndexTotalNumberUnread);
            }
        },
        addListener(context) {
            let queryScroll = null;
            let listId = {
                idUnread : [],
                idNoRep : []
            };
            queryScroll = db.collection('topics')
              .where('admin_ids', 'array-contains', context.state.ADMIN_ID)
            context.state.topic_listener = queryScroll.onSnapshot(querySnapshot =>{
                if (querySnapshot.docChanges().length){
                    // put the list of topics from firestore into the list to display
                    querySnapshot.docChanges().forEach((item,index)=> {
                        // add
                        if (item.type === "added"){
                            const data = item.doc.data();
                            let cnt_unread_message = data.count_unread
                            if (typeof data.count_unread == 'object') {
                                data.count_unread.forEach((ele) => {
                                    if (ele.user_id === context.state.ADMIN_ID && ele.is_admin) {
                                        cnt_unread_message = ele.value
                                    }
                                })
                            }
                            const inquiry = {
                                count_unread_message: cnt_unread_message,
                                count_unread_all: data.count_unread,
                                inquiry_id: data.id,
                                user_id: data.user_ids,
                                chat_id: item.doc.id,
                                last_message: data.last_message,
                                created_at : data.created_at,
                                last_message_at : data.last_message_at,
                                admin_reply : data.admin_reply,
                            }
                            //dispatch to set the number of elements of the array -> display the number of unread messages.
                            if (inquiry.count_unread_message) {
                                context.commit('PUSH_LIST_COUNT_UNREAD',inquiry);
                                listId.idUnread.push(inquiry.user_id[0]);
                            }
                            // if there are unread messages and query_admin_reply = 0 -> admin view but not rep and last_message
                            // already exists (to avoid the case that there is only topic but no message ) -> add this request to listTopicAdminSeenNotRep array
                            // and get ra user_id to show that user information.
                            if (!inquiry.count_unread_message && !inquiry.admin_reply && inquiry.last_message){
                                context.commit('PUSH_LIST_TOPIC_ADMIN_SEEN_NOT_REPLY',inquiry);
                                listId.idNoRep.push(inquiry.user_id[0]);
                            }
                            // when the function is finished, it will call the api to get the information of the user who sent the message and the admin user does not reply).
                            if (index === querySnapshot.docChanges().length - 1){
                                context.state.totalNumberUnreadOfMessages.sort((a,b) => b.last_message_at - a.last_message_at);
                                context.dispatch('getListUserById',listId);
                            }
                        }
                        // update
                        if (item.type === "modified"){
                            // when firebase detects that a record's data has been changed, it updates the record with new data
                            let dataChange = item.doc.data();
                            let chat_id = item.doc.id;
                            if (item.doc.data()){
                                let cnt_unread_message = dataChange.count_unread
                                if (typeof dataChange.count_unread == 'object') {
                                    dataChange.count_unread.forEach(ele => {
                                        if (ele.user_id === context.state.ADMIN_ID && ele.is_admin) {
                                            cnt_unread_message = ele.value
                                        }
                                    })
                                }
                                let inquiryUpdated = {};
                                // get the number of new messages to update the array
                                inquiryUpdated = {
                                    count_unread_message: cnt_unread_message,
                                    count_unread_all: dataChange.count_unread,
                                    created_at : dataChange.created_at,
                                    chat_id : chat_id,
                                    inquiry_id : dataChange.id,
                                    user_id : dataChange.user_ids,
                                    last_message : dataChange.last_message,
                                    last_message_at : dataChange.last_message_at,
                                    admin_reply : dataChange.admin_reply,
                                }
                                context.dispatch('checkStatusUpdateFirebase',inquiryUpdated);
                            }
                        }
                    })
                }
            })
        },
    },
}



