import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    //* Dashboard
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'ダッシュボード',
      },
    },

    //* manage room
    {
      path: '/room',
      name: 'room-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/room/list',
          name: 'room-list',
          component: () => import('@/views/room/List.vue'),
          meta: {
            pageTitle: 'ルーム',
          }
        },
        {
          path: '/room/log',
          name: 'room-log',
          component: () => import('@/views/room/RoomLog.vue'),
          meta: {
            pageTitle: 'ルーム',
            active: true,
            to: 'room-list',
            breadcrumb: [
              {
                text: '過去ログ',
                active: true,
              },
            ],
          }
        }
      ]
    },

    //* manage user
    {
      path: '/user',
      name: 'user-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/user/list',
          name: 'user-list',
          component: () => import('@/views/user/List.vue'),
          meta: {
            pageTitle: 'ユーザー',
          },
        },
        {
          path: '/user/create',
          name: 'user-create',
          component: () => import('@/views/user/Create.vue'),
          meta: {
            pageTitle: 'ユーザー',
            breadcrumb: [
              {
                text: 'マスターユーザー登録',
                active: true,
              },
            ],
          },
        },
        {
          path: '/user/edit/:id',
          name: 'user-edit',
          component: () => import('@/views/user/Update.vue'),
          meta: {
            pageTitle: 'ユーザー',
            breadcrumb: [
              {
                text: 'ユーザー詳細',
                active: true,
              },
            ],
          },
        },
      ],
    },

    //* manage inquiry
    {
      path: '/inquiry',
      name: 'inquiry-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/inquiry/list',
          name: 'inquiry-list',
          component: () => import('@/views/inquiry/List.vue'),
          meta: {
            pageTitle: 'お問い合わせ',
            contentRenderer: 'spaceless'
          }
        }
      ]
    },

    //* manage notification
    {
      path: '/notification',
      name: 'notification-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/notification/list',
          name: 'notification-list',
          component: () => import('@/views/notification/List.vue'),
          meta: {
            pageTitle: 'お知らせ',
            contentRenderer: 'spaceless'
          }
        },
        {
          path: '/notification/create',
          name: 'notification-create',
          component: () => import('@/views/notification/Create.vue'),
          meta: {
            pageTitle: 'お知らせ',
            notAuthenticated: true,
            contentRenderer: 'spaceless'
          }
        }
      ]
    },

    //* manage earnings
    {
      path: '/earnings',
      name: 'earnings-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/earnings/list',
          name: 'earnings-list',
          component: () => import('@/views/earnings/List.vue'),
          meta: {
            pageTitle: '売上',
          }
        }
      ]
    },

    //* manage contract
    {
      path: '/contract',
      name: 'contract-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/contract/list',
          name: 'contract-list',
          component: () => import('@/views/contract/List.vue'),
          meta: {
            pageTitle: '契約',
          }
        }
      ]
    },

    //* manage slide
    {
      path: '/slide',
      name: 'slide-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/slide/list',
          name: 'slide-list',
          component: () => import('@/views/slide/List.vue'),
          meta: {
            pageTitle: 'スライド広告',
            contentRenderer: 'spaceless',
          }
        }
      ]
    },

    //* manage account
    {
      path: '/account',
      name: 'account-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/account/list',
          name: 'account-list',
          component: () => import('@/views/account/List.vue'),
          meta: {
            pageTitle: 'アカウント管理',
          }
        }
      ]
    },

    //* manage prohibited information
    {
      path: '/prohibited-information',
      name: 'prohibited-information-list',
      component: () => import('@/views/Base.vue'),
      children: [
        {
          path: '/prohibited-information/list',
          name: 'prohibited-information-list',
          component: () => import('@/views/prohibited-information/List.vue'),
          meta: {
            pageTitle: '禁止情報設定',
          }
        }
      ]
    },

    //* Setting
    {
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/Setting.vue'),
      meta: {
        pageTitle: '設定',
      },
    },

    //* Authentication section
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        notAuthenticated: true,
        layout: 'full',
      },
    },
    {
      path: '/send-mail-reset',
      name: 'send-mail-reset',
      component: () => import('@/views/resetPassword/SendMailReset.vue'),
      meta: {
        pageTitle: 'Reset Password',
        notAuthenticated: true,
        layout: 'full',
      },
    },
    {
      path: '/confirm-reset',
      name: 'confirm-reset',
      component: () => import('@/views/resetPassword/ConfirmReset.vue'),
      meta: {
        pageTitle: 'Confirm Reset',
        notAuthenticated: true,
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import('@/views/resetPassword/ResetPassword.vue'),
      meta: {
        pageTitle: 'Reset Password',
        notAuthenticated: true,
        layout: 'full',
      }
    },
    {
      path: '/reset-password-success',
      name: 'reset-password-success',
      component: () => import('@/views/resetPassword/ResetPwSuccess.vue'),
      meta: {
        pageTitle: 'Reset Password Success',
        notAuthenticated: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        notAuthenticated: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
