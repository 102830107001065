import moment from "moment";
import { mapState } from "vuex";
import Swal from "sweetalert2";

export const baseMixin = {
  data: function () {
    return {
      message: "hello",
      user: {
        name: "test name",
      },
    };
  },

  computed: {
    ...mapState({
      src: (state) => state.loading.imageDefault,
      srcAvatar: (state) => state.loading.imageDefaultAvatar,
    }),
  },

  methods: {
    startLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "block";
    },

    endLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "none";
    },

    isEmpty(obj) {
      if (typeof obj !== "object") {
        return false;
      }
      return Object.keys(obj).length === 0;
    },

    setImageDefault(event) {
      event.target.src = this.src;
    },

    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    setImageAvatarDefault(event) {
      event.target.src = this.srcAvatar;
    },

    convertTimeToUTC(time, format) {
      return time
        ? format
          ? moment(time).utc().format(format)
          : moment(time).utc().format("yyyy-MM-DD HH:mm:ss")
        : null;
    },

    convertTimeToLocal(time, format) {
      return time
        ? format
          ? moment.utc(time).local().format(format)
          : moment.utc(time).local().format("yyyy-MM-DD HH:mm:ss")
        : null;
    },

    serialize(obj) {
      let str = [];
      // eslint-disable-next-line no-prototype-builtins
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },

    formatDateTime(datetime) {
      return datetime ? moment(datetime).format("yyyy年MM月DD日 HH:mm:ss") : "";
    },

    formatDate(date, format = "yyyy年MM月DD日") {
      return date ? moment(date).format(format) : "";
    },

    formatMoney(money) {
      money = typeof parseInt(money) == "number" ? parseInt(money) : 0;
      let val = (money / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    notifyError(message, title = "通知") {
      this.$store.commit("app/TURN_ON_NOTIFICATION", {
        title: title,
        message: message || '不具合が発生しました、システム管理者へご連絡ください。',
        typeSuccess: false,
      });
    },

    notifySuccess(message) {
      Swal.fire({
        position: 'top-right',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        width:'400px',
        timer: 3000,
        toast:true
      })
    },

    getDataSearch(form, querySearch, header, sortChild, stt) {
      Object.keys(form).map((value) => {
        if (
          typeof form[value] === "string" &&
          form[value] &&
          Array.isArray(querySearch[value])
        ) {
          querySearch[value] = [];
          querySearch[value].push(form[value]);
        } else {
          querySearch[value] = form[value];
        }
      });
      if (form.sortChild) {
        sortChild = form.sortChild;
        header = header.map((item) => {
          if (item.key === form.sortChild.split(",")[0]) {
            item.value = form.sortChild.split(",")[1];
          }
          return item;
        });
      }
      stt = form.sort ? form.sort.split(",") : [];
      if (form.sort && form.sort_by) {
        let sortBy = form.sort_by.split(",");
        header = header.map((item) => {
          if (stt.indexOf(item.key) >= 0) {
            item.value = sortBy[stt.indexOf(item.key)];
          }
          return item;
        });
      }
      return {
        querySearch: querySearch,
        header: header,
        sortChild: sortChild,
        stt: stt,
      };
    },

    submitSearch() {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.queryParam },
      });
    },

    changeSort(value, header, sttList, sortChild, querySearch, queryParam) {
      header.map((item) => {
        if (
          item.key === value.split("-")[0] ||
          item.keyQuery === value.split("-")[0]
        ) {
          if (value.split("-")[1] !== "lock") {
            item.value = value.split("-")[1];
          }
        } else {
          if (
            sttList.indexOf(item.key) < 0 ||
            (item.keyQuery && sttList.indexOf(item.keyQuery) < 0)
          ) {
            item.value = "";
          }
        }
        return item;
      });
      let stt = JSON.parse(JSON.stringify(sttList));
      if (value.split("-")[1] === "lock") {
        if (stt.indexOf(value.split("-")[0]) < 0) {
          stt.push(value.split("-")[0]);
        }
        sortChild = "";
      } else {
        if (value.split("-")[1] === "asc" || value.split("-")[1] === "desc") {
          sortChild = value.split("-")[0] + "," + value.split("-")[1];
        } else if (sortChild.split(",")[0] === value.split("-")[0]) {
          sortChild = "";
        }
        stt = stt.filter((item) => {
          return item !== value.split("-")[0];
        });
      }
      sttList = stt;
      let sortBy = header
        .filter((item) => {
          return stt.indexOf(item.keyQuery ? item.keyQuery : item.key) >= 0;
        })
        .map((item) => {
          return item.value;
        });
      querySearch.sort = sttList.toString();
      querySearch.sort_by = sortBy.toString();
      querySearch.sortChild = sortChild;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...queryParam,
          ...{
            sort: sttList.toString(),
            sort_by: sortBy.toString(),
            sortChild: sortChild,
          },
        },
      });
      return {
        header: header,
        stt: sttList,
        sortChild: sortChild,
        querySearch: querySearch,
      };
    },

    numberWithCommas(amount) {
      let number = Number(amount);
      if (isNaN(number)) {
        return amount;
      } else {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    formatFloat(number) {
      return number.split(".")[0];
    },

    formatCustomerMaster(tojicd, toegcd, totocd = "") {
      const tojicd1 = `000${tojicd}`.substr(-3, 3) || "";
      const toegcd1 = `00${toegcd}`.substr(-2, 2) || "";
      // eslint-disable-next-line eqeqeq
      if (totocd != "") {
        const totocd1 = `00000${totocd}`.substr(-5, 5) || "";
        return `${tojicd1}-${toegcd1}-${totocd1}`;
      }
      return `${tojicd1}-${toegcd1}`;
    },

    validateEmailFormat(email) {
      const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      return regExp.test(email);
    },

    formatNumber(number) {
      return Intl.NumberFormat("en-US").format(number);
    },

    downloadCsv(data, name) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", `${name}.csv`);
      a.click();
    },
    formatPrice(number) {
	    number = parseFloat(number.toString().match(/^-?\d+\.?\d{0,2}/));
	    var val = (Math.floor(number * 100) / 100).toString().split(".");
	    val [0] = val [0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	    val.join(".");
      val = "¥" + val;
      return val = val.replace("¥-", "-¥");
	  },
  },
};
