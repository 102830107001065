import firebase from 'firebase/app'
import 'firebase/firestore'
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASECONFIG_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASECONFIG_authDomain,
  databaseURL: process.env.VUE_APP_FIREBASECONFIG_databaseURL,
  projectId: process.env.VUE_APP_FIREBASECONFIG_projectId,
  storageBucket: process.env.VUE_APP_FIREBASECONFIG_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIREBASECONFIG_messagingSenderId,
  appId: process.env.VUE_APP_FIREBASECONFIG_appId,
  measurementId: process.env.VUE_APP_FIREBASECONFIG_measurementId,
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore()

export {
  // eslint-disable-next-line import/prefer-default-export
  db,
}
