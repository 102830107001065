import { $themeBreakpoints } from '@themeConfig'
import router from "@/router"
import {apiService} from "@/services/api.service";
import JwtService from "@/services/jwt.service";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    showNotification: false,
    notification: {},
    admin: {
      id: null,
      login_id: null,
      email: null,
      name: null,
      phone_number: null,
      status: null,
    },
    revenueList:[],
    authStatus: '',
    mailSent: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    getAdminInfo: state => {
      return state.admin;
    },
    getRevenueList1: state =>{
      return state.revenueList;
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_ADMIN_INFO(state, val) {
      state.admin = val;
    },
    CLEAR_ADMIN_INFO(state) {
      state.admin = {
        id: null,
        login_id: null,
        email: null,
        name: null,
        phone_number: null,
        status: null,
      }
    },
    TURN_ON_NOTIFICATION(state, payload) {
      state.showNotification = true;
      state.notification = {
        title: payload.title,
        message: payload.message,
        typeSuccess: payload.typeSuccess,
      }
    },
    TURN_OFF_NOTIFICATION(state) {
      state.showNotification = false;
    },
    MODIFY_REVENUE_LIST(state, payload){
      state.revenueList = payload;
    },
    SET_AUTH_STATUS(state, payload) {
      state.authStatus = payload
    },
    SET_MAIL_SENT(state, payload) {
      state.mailSent = payload
    },
    SET_LIST_COUNT_UNREAD(state,payload){
      state.totalNumberOfMessages = payload;
    }
  },
  actions: {
    ['verifyAuth'](context) {
      return new Promise(resolve => {
        if (JwtService.getToken()) {
          apiService.get("auth/verify")
                  .then(async ({data}) => {
                    await JwtService.saveToken(data.data.token);
                    context.commit('SET_ADMIN_INFO', data.data.admin);
                    resolve();
                  }).catch(({response}) => {
                      console.log(response)
                      router.push({ name: "login"});
                      JwtService.destroyToken()
                      resolve();
          });
        } else {
          resolve();
          router.push({ name: "login"});
        }
      })
    },
    setAdminInfo(context) {
      const admin = JSON.parse(localStorage.getItem('user'))
      context.commit('SET_ADMIN_INFO', admin)
    },
    setAuthStatus(context, authStatus) {
      context.commit('SET_AUTH_STATUS', authStatus)
    },
    setMailSent(context, authStatus) {
      context.commit('SET_MAIL_SENT', authStatus)
    },
    modifyRevenueList(context, revenueList){
      context.commit("MODIFY_REVENUE_LIST", revenueList);
    },
  },
}
