import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import totalCountUnreadMsg from  './inquiry/index'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    totalCountUnreadMsg
  },
  strict: process.env.DEV,
})
